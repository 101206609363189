<template>
  <AppRow permission="KYC_VIEW_LIST">
    <template v-slot>
      <v-col>
        <ApolloQuery
          :query="require('../graphql/queries/getKycReviewUsers.gql')"
          :variables="{
            skip: (pageIndex - 1) * itemsPerPage,
            limit: itemsPerPage,
          }"
        >
          <template
            v-slot="{ result: { error: error, data: data }, isLoading: isLoading }"
          >
            <v-col>
              <!-- Error -->
              <AppNotification
                v-if="error"
                :message="error.message"
              />
              <!-- Result -->
              <v-card
                v-else-if="data || isLoading === 1"
                :loading="isLoading === 1"
                class="px-4 pb-3"
                flat
              >
                <template v-if="data">
                  <v-data-table
                    :headers="headers"
                    :items="data.getKycReviewUsers.users"
                    :items-per-page="itemsPerPage"
                    disable-sort
                    hide-default-footer
                  >
                    <template v-slot:[`item.userId`]="{ item }">
                      {{ item.userId }}
                    </template>
                    <template v-slot:[`item.docs`]="{ item }">
                      <v-row>
                        <template>
                          <v-col
                            v-for="url in item.docs"
                            :key="url"
                          >
                            <img
                              :src="url"
                              :style="{
                                width: '100%',
                                display: 'block',
                              }"
                              @click="upd(url)"
                            >
                          </v-col>
                        </template>
                      </v-row>
                    </template>

                    <template v-slot:[`item.kycVerified`]="{ item }">
                      <v-btn
                        :to="{ name: 'users', params: { id: item.userId }}"
                        class="text-none"
                        depressed
                        height="36"
                        x-small
                        style="margin-top: .4rem"
                      >
                        Подробнее об игроке
                      </v-btn>

                      <ApolloMutation
                        :mutation="require('../graphql/mutations/verifyUserKyc.gql')"
                        :refetch-queries="refetchQueriesAfterMyMutation"
                        :variables="{
                          userId: item.userId,
                          verified: false,
                        }"
                      >
                        <template v-slot="{ mutate }">
                      <v-btn
                        class="text-none ma-1"
                        depressed
                        height="36"
                        x-small
                        style="margin-top: .7rem !important"
                        @click="mutate()"
                      >
                        Отклонить
                      </v-btn>
                        </template>
                      </ApolloMutation>

                          <ApolloMutation
                            :mutation="require('../graphql/mutations/verifyUserKyc.gql')"
                            :refetch-queries="refetchQueriesAfterMyMutation"
                            :variables="{
                          userId: item.userId,
                          verified: true,
                        }"
                          >
                            <template v-slot="{ mutate }">
                      <v-btn
                        class="text-none ma-1"
                        color="primary"
                        depressed
                        height="36"
                        x-small
                        style="margin-top: .7rem !important"
                        @click="mutate()"
                      >
                        Одобрить
                      </v-btn>
                        </template>
                      </ApolloMutation>
                    </template>
                  </v-data-table>
                  <v-divider />
                </template>

                <template>
                  <v-dialog
                    v-model="docOpened"
                    max-width="1000px"
                    persistent
                  >
                    <img
                      :src="docUrl"
                      :style="{
                        width: '100%',
                        display: 'block',
                      }"
                      @click="upd"
                    >
                  </v-dialog>
                </template>
              </v-card>

              <!-- No result -->
              <AppNotification
                v-else
                message="Нет результата :("
              />
            </v-col>
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>
<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';
import getKycReviewUsers from '@/graphql/queries/getKycReviewUsers.gql';

export default {
  components: {
    AppNotification,
    AppRow,
  },
  data() {
    return {
      headers: [
        // { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'userId:', value: 'userId', class: 'white--text text-subtitle-2' },
        { text: 'Docs:', value: 'docs', class: 'white--text text-subtitle-2' },
        // { text: 'Сумма:', value: 'amount', class: 'white--text text-subtitle-2' },
        // { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        // { text: 'Номер счёта:', value: 'accountNumber', class: 'white--text text-subtitle-2' },
        // { text: 'Доп:', value: 'walletRecipient', class: 'white--text text-subtitle-2' },
        // { text: 'Статус:', value: 'status', class: 'white--text text-subtitle-2' },
        // { text: 'Дата:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        // { text: '', value: 'action', align: 'center' },
        { text: '', value: 'kycVerified', class: 'white--text text-subtitle-2' },
      ],
      editedWithdraw: {
        purseType: 'INR',
      },
      selectPurseType: [
        { text: 'INR', key: 'INR' },
        { text: 'BTC', key: 'BTC' },
        { text: 'USD', key: 'USD' },
        { text: 'EUR', key: 'EUR' },
        { text: 'ETC', key: 'ETC' },
        { text: 'UAH', key: 'UAH' },
        { text: 'RUB', key: 'RUB' },
        { text: 'LTC', key: 'LTC' },
        { text: 'DEMO', key: 'DEMO' },
        { text: 'ETH', key: 'ETH' },
        { text: 'BRL', key: 'BRL' },
        { text: 'BCH', key: 'BCH' },
        { text: 'BNB', key: 'BNB' },
        { text: 'DASH', key: 'DASH' },
        { text: 'DOGE', key: 'DOGE' },
        { text: 'TRX', key: 'TRX' },
        { text: 'USDT', key: 'USDT' },
        { text: 'XMR', key: 'XMR' },
        { text: 'ZEC', key: 'ZEC' },
        { text: 'XRP', key: 'XRP' },
        { text: 'KZT', key: 'KZT' },
        { text: 'VND', key: 'VND' },
        { text: 'UZS', key: 'UZS' },
        { text: 'IDR', key: 'IDR' },
        { text: 'AZN', key: 'AZN' },
        { text: 'KGS', key: 'KGS' },
        { text: 'PKR', key: 'PKR' },
        { text: 'BDT', key: 'BDT' },
        { text: 'CLP', key: 'CLP' },
        { text: 'PEN', key: 'PEN' },
        { text: 'COP', key: 'COP' },
        { text: 'MXN', key: 'MXN' },
        { text: 'PYG', key: 'PYG' },
        { text: 'UYU', key: 'UYU' },
        { text: 'BOB', key: 'BOB' },
        { text: 'CRC', key: 'CRC' },
        { text: 'GTQ', key: 'GTQ' },
        { text: 'PAB', key: 'PAB' },
        { text: 'NIO', key: 'NIO' },
        { text: 'ARS', key: 'ARS' },
        { text: 'SVC', key: 'SVC' },
        { text: 'NGN', key: 'NGN' },
      ],
      itemsPerPage: 5,
      pageIndex: 1,
      walletType: 'manual_withdraw',
      status: 'PENDING',
      dialog: false,
      withdrawDialog: false,
      reason: '',
      withdrawId: null,
      accepted: 'ACCEPTED',
      denied: 'DENIED',
      isAccept: false,
      docOpened: false,
      docUrl: '',
    };
  },
  computed: {
    dialogTitle() {
      return this.isAccept ? 'Перевести в статус "отправлено"' : 'Отклонить заявку на вывод';
    },
    buttonText() {
      return this.isAccept ? 'Перевести' : 'Отклонить';
    },
  },
  methods: {
    upd(url) {
      this.docOpened = !this.docOpened;
      this.docUrl = url;
    },
    refetchQueriesAfterMyMutation() {
      this.dialog = false;
      this.withdrawDialog = false;

      return [{
        query: getKycReviewUsers,
        variables: {
          skip: (this.pageIndex - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        },
      }];
    },
    denyWithdraw(item) {
      this.isAccept = false;
      this.withdraw(item);
    },
    acceptWithdraw(item) {
      this.isAccept = true;
      this.withdraw(item);
    },
    withdraw(item) {
      this.dialog = true;
      this.withdrawId = +item.id;
      this.reason = '';
    },
    firstPage() {
      this.pageIndex = 1;
    },
    createWithdrawClose() {
      this.withdrawDialog = false;
      this.$nextTick(() => {
        this.editedWithdraw = {
          purseType: 'INR',
        };
      });
    },
  },
};
</script>
