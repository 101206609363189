var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"permission":"KYC_VIEW_LIST"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/getKycReviewUsers.gql'),"variables":{
          skip: (_vm.pageIndex - 1) * _vm.itemsPerPage,
          limit: _vm.itemsPerPage,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ref_result = ref.result;
        var error = ref_result.error;
        var data = ref_result.data;
        var isLoading = ref.isLoading;
return [_c('v-col',[(error)?_c('AppNotification',{attrs:{"message":error.message}}):(data || isLoading === 1)?_c('v-card',{staticClass:"px-4 pb-3",attrs:{"loading":isLoading === 1,"flat":""}},[(data)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":data.getKycReviewUsers.users,"items-per-page":_vm.itemsPerPage,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.userId)+" ")]}},{key:"item.docs",fn:function(ref){
        var item = ref.item;
return [_c('v-row',[_vm._l((item.docs),function(url){return _c('v-col',{key:url},[_c('img',{style:({
                              width: '100%',
                              display: 'block',
                            }),attrs:{"src":url},on:{"click":function($event){return _vm.upd(url)}}})])})],2)]}},{key:"item.kycVerified",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",staticStyle:{"margin-top":".4rem"},attrs:{"to":{ name: 'users', params: { id: item.userId }},"depressed":"","height":"36","x-small":""}},[_vm._v(" Подробнее об игроке ")]),_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/verifyUserKyc.gql'),"refetch-queries":_vm.refetchQueriesAfterMyMutation,"variables":{
                        userId: item.userId,
                        verified: false,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var mutate = ref.mutate;
return [_c('v-btn',{staticClass:"text-none ma-1",staticStyle:{"margin-top":".7rem !important"},attrs:{"depressed":"","height":"36","x-small":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Отклонить ")])]}}],null,true)}),_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/verifyUserKyc.gql'),"refetch-queries":_vm.refetchQueriesAfterMyMutation,"variables":{
                        userId: item.userId,
                        verified: true,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var mutate = ref.mutate;
return [_c('v-btn',{staticClass:"text-none ma-1",staticStyle:{"margin-top":".7rem !important"},attrs:{"color":"primary","depressed":"","height":"36","x-small":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Одобрить ")])]}}],null,true)})]}}],null,true)}),_c('v-divider')]:_vm._e(),[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.docOpened),callback:function ($$v) {_vm.docOpened=$$v},expression:"docOpened"}},[_c('img',{style:({
                      width: '100%',
                      display: 'block',
                    }),attrs:{"src":_vm.docUrl},on:{"click":_vm.upd}})])]],2):_c('AppNotification',{attrs:{"message":"Нет результата :("}})],1)]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }